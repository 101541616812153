import React, { useEffect, useState } from "react"
import { CpiService } from "../services/cpi.service"

function Protocol({}) {
  const [protocol, setProtocol] = useState("")

  async function init() {
    let protocol = await CpiService.getProtocol()
    setProtocol(protocol.content)
  }

  useEffect(() => {
    async function init() {
      let protocol = await CpiService.getProtocol()
      setProtocol(protocol.content)
    }

    init()
  }, [])

  return (
    <p
      dangerouslySetInnerHTML={{
        __html: protocol,
      }}
    ></p>
  )
}

export default Protocol
